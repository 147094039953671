//
// Formstone Stepper Plugin
// --------------------------------------------------

.stepper{
  position: relative;
  display: inline-block;
  max-width: 83px;
  width: 83px;
  //overflow: hidden;

  input[type="number"]{
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    -moz-appearance: textfield;
  }

  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &:hover{
   .stepper-arrow.up ,
   .stepper-arrow.down {
     transform: translateX(0);
   }
  }

  &-arrow{
    position: absolute;
    top: 50%;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    font-family: $font-icons;
    cursor: pointer;
    color: $gray-dark;
    transition: .3s all ease;

    &:hover{
      color: $primary
    }

    &.up{
      //transform: translateX(100%);
      right: 0;
      text-align: left;

      &:before{
        content: '\e939';
      }
    }

    &.down{
      //transform: translateX(-100%);
      left: 0;
      text-align: right;

      &:before{
        content: '\e93a';
      }
    }
  }

  &.disabled{
    .stepper-arrow{
      pointer-events: none;
      opacity: .5;
    }
  }
}
