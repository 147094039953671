//
// Dividers
// --------------------------------------------------
.divider {
  display: block;
  width: 100%;
  height: 1px;
  background: $gray-1;
}

.divider-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:before,
  &:after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 1px;
    background: $gray-1;
  }
  
  > * {
    flex-basis: 30%;
    padding-left: 15px;
    padding-right: 15px;
    white-space: nowrap;
  }
  
  & {
    margin-top: 35px;
    margin-bottom: 35px;
    @include media-breakpoint-up(md) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
}