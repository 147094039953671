//
// Icons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.icon {
  display: inline-block;
  line-height: 1;
  text-align: center;

  &:before {
    display: inline-block;
    font-weight: 400;
    font-style: normal;
    speak: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

// Alternate icons
// --------------------------------------------------
.page {
  .icon-default {
    color: $body-color;
  }

  .icon-light {
    color: $gray-light;
  }
 
  .icon-primary {
    color: $primary;
  }

  .icon-black {
    color: $black;
  }

  a {
    &.icon-default {
      &:hover { color: $secondary; }
    }
    
    &.icon-light {
      &:hover {
        color: $primary;
        border-color: $primary;
      }
    }

    &.icon-primary {
      color: $primary;

      &:hover { color: $black; }
    }
  }
}

// Button Sizes
// --------------------------------------------------
.page {
  .icon-xxs {
    font-size: 15px;
  }
  
  .unit {
    .icon-xxs {
      position: relative;
      top: 1px;
    }
  }

  .icon-sm {
    font-size: 20px;
    line-height: 20px;
  }

  .icon-md {
    font-size: 22px;
    line-height: 22px;
  }

  .icon-lg {
    font-size: 42px;
  }

  .icon-xxl {
    font-size: 48px;

    @include media-breakpoint-up(lg) {
      font-size: 80px;
    }
  }
}

// Icon shapes
// --------------------------------------------------
.icon-circle {
  border-radius: 100%;
}

.icon-square {
  width: 3.3em;
  height: 3.3em;
  line-height: 3.3em;
  border: 1px solid;
}

.icon.icon-circle {
  width: 2.2em;
  height: 2.2em;
  line-height: 2.2em;
}

.icon.icon-round {
  border-radius: 4px;
  width: 2.2em;
  height: 2.2em;
  line-height: 2.2em;
}

.icon-list-wrap {
  > *,
  &:before {
    vertical-align: middle;
    margin-right: 6px;
  }
}