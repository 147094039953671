/*
*
* Form styles
* --------------------------------------------------
*/

//== Forms variables
//
$input-height: 50px;
$form-input-color: $gray;
$form-input-color-placeholder: $form-input-color;

$form-input-background: $gray-lighter;
$form-input-border-color: $gray-lighter;
$form-input-border-color-radius: 0;

$form-input-font-size: 14px;
$input-line-height: 22px;
$input-font-weight: 400;

$error-color: #d9534f;
$success-color: #5cb85c;

$form-input-border: 1px solid $form-input-background;
$input-padding-horizontal: 19px;
$input-line-height: round($form-input-font-size * 1.7);

$input-padding-vertical: round(($input-height - $input-line-height) / 2);
$input-padding: $input-padding-vertical $input-padding-horizontal;

@if ($form-input-border != none) {
  $input-padding-vertical: round(($input-height - $input-line-height - (nth($form-input-border, 1) * 2)) / 2);
  $input-padding: $input-padding-vertical $input-padding-horizontal;
}

$form-textarea-default-height: 216px;
$form-textarea-default-min-height: $input-height;
$form-textarea-default-max-height: round($form-textarea-default-height * 1.7);

//
// Form styles
// --------------------------------------------------

.rd-mailform {
  position: relative;
  text-align: left;

  .form-label {
    color: $form-input-color;
  }

  * + .button {
    margin-top: 25px;
  }

  .price + .button {
    margin-top: 35px;
  }

  @include media-breakpoint-up(md) {
    .price + .button {
      margin-top: 55px;
    }
  }
}

input:-webkit-autofill ~ label,
input:-webkit-autofill ~ .form-validation {
  color: $black !important;
}

.form-label,
.form-label-outside {
  margin-bottom: 0;
  font-size: $form-input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
}

.form-input {
  display: block;
  width: 100%;
  min-height: $input-height;
  padding: $input-padding-vertical $input-padding-horizontal;
  font-size: $form-input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $form-input-color;
  background-color: $form-input-background;
  background-image: none;
  border: $form-input-border;
  border-radius: $form-input-border-color-radius;
  -webkit-appearance: none;
  transition: .3s ease-in-out;
  box-shadow: none;

  @if ($form-input-border) {
    border: $form-input-border;
  }

  &:focus {
    outline: 0;
    background: $white;
    border-color: $gray-1;
    box-shadow: none;
  }
}

textarea.form-input {
  height: $form-textarea-default-height;
  min-height: $form-textarea-default-min-height;
  max-height: $form-textarea-default-max-height;
  resize: vertical;
}

// Form wrapper
// -------------------------

.form-wrap {
  position: relative;
}

.form-wrap_icon {
  position: relative;
  .form-input {
    padding-left: 60px;
  }

  .form-label {
    left: 60px;
  }

  &::before {
    position: absolute;
    top: $input-height / 2;
    left: 22px;
    transform: translateY(-61%);
    margin-right: 7px;
    font-size: 24px;
    line-height: 24px;
    color: $gray-4;
  }

  &__label-outside {
    &:before {
      top: auto;
      bottom: $input-height / 2;
      transform: translateY(45%);
    }
  }
}

* + .form-wrap {
  margin-top: 20px;
}

.form-wrap + .form-wrap {
  margin-top: 20px;
}

// Form labels
// -------------------------

.form-label {
  position: absolute;
  top: $input-height / 2;
  left: $input-padding-horizontal;
  pointer-events: none;
  z-index: 9;
  transition: .25s;
  will-change: transform;
  transform: translateY(-50%);

  &.focus {
    opacity: 0;
  }

  &.auto-fill {
    color: $form-input-color;
  }
}

.form-label-outside {
  font-family: $font-family-sans-serif-2;
  font-size: 14px;
  line-height: 1.2;
  color: $black;
  text-transform: uppercase;
  letter-spacing: .08em;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  @include media-breakpoint-up(md) {
    position: static;

    &,
    &.focus,
    &.auto-fill {
      transform: none;
    }
  }
}

.form-label-outside + .form-input,
.form-label-outside ~ .select2 {
  margin-top: 5px;
}

.form-label-outside ~ .form-validation {
  top: 37px;
}

// Form validation
// -------------------------

.form-validation {
  position: absolute;
  right: 4px;
  top: 1px;
  z-index: 11;
  margin-top: 2px;
  font-size: 10px;
  line-height: 12px;
  font-style: italic;
  letter-spacing: 0;
  color: $error-color;
  transition: .3s;
}

.form-validation-left {
  .form-validation {
    right: auto;
    top: 100%;
    left: 0;
  }
}

// Form output
// -------------------------

#form-output-global {
  position: fixed;
  bottom: 30px;
  left: 15px;
  visibility: hidden;
  transform: translateX(-500px);
  transition: .3s all ease;
  z-index: 9999999;

  &.active {
    transform: translateX(0);
    visibility: visible;
  }

  @include media-breakpoint-up(sm) {
    left: 30px;
  }
}

.form-output {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 2px;
  transition: .3s;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &.error {
    color: $error-color;
  }

  &.success {
    color: $success-color;
  }
}

// Radio and Checkbox Custom
// -------------------------

// Base Styles
//
.radio .radio-custom,
.radio-inline .radio-custom,
.checkbox .checkbox-custom,
.checkbox-inline .checkbox-custom {
  opacity: 0;

  &[type='checkbox'], &-dummy {
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: -32px;
    margin-top: 0;
    outline: none;
    cursor: pointer;
    border: 1px solid $gray-6;
  }

  &-dummy {
    pointer-events: none;
    background: $gray-lighter;
    transition: 250ms;

    &:after {
      position: absolute;
      opacity: 0;
    }
  }

  &:focus {
    outline: none;
  }
}

.radio-custom:checked + .radio-custom-dummy:after,
.checkbox-custom:checked + .checkbox-custom-dummy:after {
  opacity: 1;
}

.checkbox-custom:checked ~ .checkbox-custom-dummy {
  background: $gray-darker;
}

// Custom Radio Styles
//
.radio,
.radio-inline {
  position: relative;
  padding-right: 10px;
  font-family: $font-family-sans-serif-2;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .08em;
  text-transform: uppercase;
  color: $black;
  padding-left: 34px;
  cursor: pointer;

  .radio-custom-dummy {
    top: 1px;
    left: 0;
    width: 19px;
    height: 19px;
    margin-left: 0;
    border-radius: 50%;

    &:after {
      content: '';
      top: 4px;
      right: 4px;
      bottom: 4px;
      left: 4px;
      background: $black;
      border-radius: 50%;
      opacity: 0;
      transition: .15s;
    }
  }

  & + p {
    padding-left: 34px;
  }
}

// Custom Checkbox Styles
//
.checkbox,
.checkbox-inline {
  position: relative;
  font-size: $form-input-font-size;
  line-height: $input-line-height;
  font-weight: $input-font-weight;
  color: $form-input-color;
  padding-left: 36px;
  cursor: pointer;

  .checkbox-custom-dummy {
    pointer-events: none;
    margin-left: 0;
    left: 0;
    top: 0;

    &:after {
      content: '\e934';
      font-family: $font-icons;
      font-size: 14px;
      line-height: 10px;
      position: absolute;
      top: 3px;
      left: 3px;
      color: $white;
    }
  }
}

// Custom
.rd-mailform_style-1 {
  * + .button {
    margin-top: 30px;
  }
}

@include media-breakpoint-up(md) {
  .rd-mailform_style-1 {
    * + .button {
      margin-top: 40px;
    }
  }
}

.rd-mailform_style-2 {
  max-width: 418px;

  & + .list-small {
    margin-top: 25px;
  }

  .form-wrap.group-xl {
    margin-top: 20px;
    @include media-breakpoint-up(xl) {
      margin-top: 40px;
    }
  }
}

.rd-mailform-inline-flex {
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    > .form-wrap + .form-wrap,
    > .form-wrap + .button {
      margin-left: 10px;
      margin-top: 0;
    }
    > .form-wrap {
      flex-grow: 1;
    }
  }

  &.rd-mailform,
  &.rd-search {
    .button {
      display: flex; 
      justify-content: center;
      flex-shrink: 0;
      width: auto; 
      min-height: $input-height;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.rd-mailform_responsive {
  @include media-breakpoint-down(sm) {
    $input-height: 50px;
    .form-input {
      padding-top: ($input-height - $input-line-height) / 2;
      padding-bottom: ($input-height - $input-line-height) / 2;
      min-height: $input-height;
    }

    .form-label {
      top: $input-height / 2;
    }

    .form-wrap_icon::before {
      top: $input-height / 2 + 1px;
    }
  }
}

//register form
//
.register-form,
.login-form {
  * + .group,
  * + form {
    margin-top: 20px;
  }
  p {
    font-size: 14px;
  }
}

.rd-mailform_sizing-1 {
  max-width: 470px;
  margin-left: auto;
  margin-right: auto;
}

.rd-mailform_boxed {
  padding: 20px;
  
  .form-label-outside ~ .form-validation {
    top: 22px;
  } 
}

.rd-mailform_boxed__inner {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  transform: translateY(-10px);
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -10px;

  > * {
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.box-element {
  > * {
    width: 100%;
  }

  .form-wrap {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    height: 100%;
  }
}

.box-element_xs {
  width: 50%;
}

.box-element_sm,
.box-element_md {
  width: 100%;
}

* + .rd-mailform.rd-mailform_boxed {
  margin-top: 35px;
}

@include media-breakpoint-up(md) {
  .rd-mailform_boxed__inner {
    transform: translateY(-20px);
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px;

    > * {
      margin-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .box-element_xs {
    width: 25%;
  }

  .box-element_sm,
  .box-element_md {
    width: 50%;
  }
}

@include media-breakpoint-up(lg) {
  .box-element_xs {
    width: 20%;
  }

  .box-element_sm,
  .box-element_md {
    width: 30%;
  }

  * + .rd-mailform.rd-mailform_boxed {
    margin-top: 50px;
  }
}

@include media-breakpoint-up(xl) {
  .rd-mailform_boxed__inner {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-left: 0;
    margin-right: 0;
  }

  .box-element_xs {
    width: 14.4%;
  }

  .box-element_sm {
    width: 23.2%;
  }

  .box-element_md {
    width: 24%;
  }
}

.rd-mailform_modern {
  .form-label,
  .form-input,
  .select2-selection__rendered {
    font-family: $font-family-sec;
    color: $black;
    font-size: 14px;
  }
  
  .form-label {
    top: 57%;
    left: 25px;
    transform: translateY(-50%);
  }

  .form-wrap {
    font-family: $font-family-sec;
    color: $black;
  }

  .form-input,
  .select2-container--bootstrap .select2-selection--single {
    padding-left: 5px;
    cursor: pointer;
    border: 0;
  }
  .form-input {
    padding-right: 0;
    -webkit-text-fill-color: $black;
    
    ::-moz-placeholder {
      color: $black;
    }
    ::-webkit-input-placeholder {
      color: $black;
    }
    :-ms-input-placeholder {
      color: $black;
    }
  }

  .rd-mailform_boxed__inner {
    margin-left: -8px;
    margin-right: -8px;
    > * {
      padding-left: 8px;
      padding-right: 8px;
    }

    > *:not(:last-child) {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        background: #e5e5e5;
      }
    }
  }

  .select2-results__option {
    font-family: $font-family-sec;
    color: $black;
  }

  .form-label-outside ~ .form-validation {
    top: 21px;
  }

  @include media-breakpoint-down(xs) {
    .box-element {
      margin-top: 30px;
    }

    .box-element_xs {
      width: 100%;
    }
  }

  @include media-breakpoint-up(lg) {
    .form-label,
    .form-input,
    .select2-selection__rendered {
      font-size: 16px;
    }

    .select2-selection--single {
      height: 62px;
      line-height: 62px;
    }

    @include media-breakpoint-down(lg) {
      .box-element_xs {
        width: 25%;
      }

      .box-element_sm,
      .box-element_md {
        width: 50%;
      }
    }
  }
  
  @include media-breakpoint-up(xl) {
    .rd-mailform_boxed__inner {
      align-items: stretch;

      > *:not(:last-child) {
        position: relative;

        &::before {
          top: 50%;
          right: 0;
          width: 1px;
          height: 80%;
          transform: translateY(-50%);
        }
      }
    }
    
    .form-wrap {
      padding: 20px;
    }
    
    .form-input {
      padding-top: 10px;
      padding-bottom: 14px;
      line-height: 35px;
      .mac-os & {
        line-height: 32px;
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }

    .select2-selection .select2-selection--single,
    .select2-selection__rendered {
      height: 60px; 
      line-height: 43px;
    }

    .form-label, 
    .form-input,
    .select2-selection__rendered {
      font-size: 24px;
      text-overflow: ellipsis;
    }
  
    .box-element {
      &:last-child {
        display: flex;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .desktop {
    .rd-mailform_modern {
      .form-input,
      .select2-container--bootstrap .select2-selection {
        background: $white;
        transition: .4s;

        &:hover {
          background: $gray-lighter;
        }
      }

      .form-label-outside ~ .form-validation {
        top: auto;
        right: auto;
        bottom: 5px;
        left: 28px;
      }
    }
  }
}