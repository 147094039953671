//pricing table
//------------------------------------

.pricing-table {
  position: relative;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  border: 1px solid $gray-1;
  text-align: center;

  .price {
    span:first-of-type {
      font-size: 40px;
      font-weight: 100;
      color: $primary;
    }
    span:last-of-type {
      font-size: 14px;
    }
  }

  &.pricing-table-label {
    .pricing-label {
      display: flex;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 27px;
  }
}

.pricing-header {
  padding: 15px 0;
  border-bottom: 1px solid $gray-1;
  
  h6 {
    color: $gray-darker;
  }

  @include media-breakpoint-up(md) {
    padding-top: 29px;
    padding-bottom: 27px;
  }
}

.pricing-body {
  padding:15px 0;
  .list {
    font-size: 14px;
    > li + li {
      margin-top: 7px;
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: 41px;
  }
}

.pricing-footer {
  padding:15px 0;

  p{
    font-size: 12px;
    font-style: italic;
  }

  @include media-breakpoint-up(md) {
    padding-top: 30px;
    padding-bottom: 35px;

    * + p {
      margin-top: 41px;
    }
  }
}

.pricing-label {
  display: none;

  position: absolute;
  top: -36px;
  right: 26px;
  z-index: 1;
  width: 86px;
  height: 86px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $white;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    fill: $primary;
  }
}

//pricing-table-custom
.pricing-table-custom {
  padding-top: 30px;

  * + .list-xxs {
    margin-top: 30px;
  }

  @include media-breakpoint-up(md) {
    padding-top: 80px;

    * + .list-xxs {
      margin-top: 115px;
    }
  }
}

//wrap
.pricing-table-wrap {
  padding-top: 40px;

}