//
// Slick carousel
// --------------------------------------------------

// Base styles
// --------------------------------------------------

$slick-font-path: "./fonts/" !default;
$slick-font-family: $font-family-base !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: $white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\e5cb" !default;
$slick-next-character: "\e5cc" !default;
$slick-dot-character: "•" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 1 !default;

.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  min-height: 1px;

  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}


@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  }
  @else {
    @return url($slick-loader-path + $url);
  }
}

@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return font-url($url);
  }
  @else {
    @return url($slick-font-path + $url);
  }
}

// Appearance styles
// --------------------------------------------------


.slick-list {
  .slick-loading & {
    background: #fff slick-image-url("../images/ajax-loader.gif") center center no-repeat;
  }
}

/* Icons */
@if $slick-font-family == "slick" {
  @font-face {
    font-family: "slick";
    src: slick-font-url("slick.eot");
    src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}


/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
  text-align: center;
  font-size: 0;
  cursor: pointer;
  color: transparent;
  background: $black;
  top: 50%;
  border-radius: 50%;
  transform: translate(0, -50%);
  transition: 300ms ease-in-out;
  padding: 0;
  border: none;
  outline: none;
  z-index: 10;

  &:hover {
    outline: none;
    background: $white;
    &:before {
      color: $black;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
  
  @include media-breakpoint-up(xxl) {
    width: 64px;
    height: 64px;
    line-height: 64px;
  }
}

.slick-prev:before, 
.slick-next:before {
  font-family: 'Material Icons';
  font-size: 24px;
  color: $slick-arrow-color;
  opacity: $slick-opacity-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 30px;
  [dir="rtl"] & {
    left: auto;
    right: 30px;
  }
  &:before {
    content: $slick-prev-character;
    [dir="rtl"] & {
      content: $slick-next-character;
    }
  }
}

.slick-next {
  right: 30px;
  [dir="rtl"] & {
    left: 30px;
    right: auto;
  }
  &:before {
    content: $slick-next-character;
    [dir="rtl"] & {
      content: $slick-prev-character;
    }
  }
}

/* Dots */

.slick-slider {
  
}

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover, &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: $slick-dot-character;
        width: 20px;
        height: 20px;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}

// custom
.carousel-parent {
  .slick-prev,
  .slick-next {
    top: auto;
    bottom: 65px;
    transform: none;
  }
  
  .slick-prev {
    right: auto;
    left: 50%;
    margin-left: -55px;
  }
  
  .slick-next {
    left: auto;
    right: 50%;
    margin-right: -55px;
  }
  
  @include media-breakpoint-up(xxl) {
    .slick-prev {
      margin-left: -69px;
    }

    .slick-next {
      margin-right: -69px;
    }
  }
  
  @include media-breakpoint-down(sm) {
    .slick-prev,
    .slick-next {
      display: none !important;
    }
  }
}
.carousel-child {
  .item {
    position: relative;
    transition: 280ms ease-in-out;
    cursor: pointer;
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      background: rgba($gray-darker, .7);
      transform: scale(.75);
      will-change: transform, opacity;
      transition: .33s;
    }
  }
  
  .item:hover,
  .slick-current {
    &::after {
      opacity: 1;
      transform: scale(1.01);
    }
  }
}

// gallery
.slick-gallery {
  width: 100%;
  margin: 0 auto;
}