//
// Posts
// --------------------------------------------------

.first-letter {
  &:first-letter {
    float: left;
    display: block;
    padding: 22px 30px;
    margin-right: 20px;
    margin-top: 8px;
    line-height: 40px;
    font-size: 40px;
    border-radius: 4px;
    background-color: $primary;
    color: $white;
    font-weight: 100;
    text-align: center;
    vertical-align: top;
  }
}

// Post
.page .post-inline {
  > * + * { margin-top: 5px; }
}

.post-inline__header,
.post-inline__footer {
  font-size: 12px;
  line-height: 1.4;
  @include spacing(20px, 5px);
  color: $body-color;

  > * {
    display: inline-block;
  }
}

.post-inline__time,
.post-inline__comment {
  color: $primary;
}

.post-inline__link {
  color: $black;

  a { @include link(inherit, $primary); }
}

.post-inline__text {
  @include small;
}

// Post classic
//

* + .post-classic-body,
.post-classic-title + * {
  margin-top: 30px;
}

* + .post-meta,
* + .post-classic-footer,
* + .post-minimal-footer {
  margin-top: 20px;
}

* + .post-classic-title {
  margin-top: 25px;
}

* + .carousel-post-gallery,
* + .entry-video,
* + .post-link,
* + .post-quote,
* + .post-audio,
* + .img-thumbnail-variant-1 {
  margin-top: 40px;
}

* + .button-link {
  margin-top: 20px;
}

.post-classic-title a {
  &:hover {
    color: $primary;
  }
}

.post-classic-title-icon {
  position: relative;

  &[class*="linear-icon-"]:before {
    position: relative;
    top: -1px;
    display: inline-block;
    float: left;
    width: 30px;
    margin-right: 10px;
    height: 30px;
    vertical-align: bottom;
    border-radius: 50%;
    background-color: $primary;
    color: $white;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    
    @include media-breakpoint-up(md) {
      top: 4px;
    }
    
    @include media-breakpoint-up(xl) {
      top: 12px;
    }
  }
}

.page .post-classic-footer {
  @include media-breakpoint-up(xl) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > * + * {
      margin-top: 0;
    }
  }
}

.post-meta {
  font-size: 12px;
  font-style: italic;
}

.meta-author {
  color: $gray;
  &:hover {
    color: $primary;
  }
}

// Post link
.post-link {
  padding: 10px 20px;
  background-color: $primary;
  text-align: left;

  * {
    color: $white;
  }

  > a {
    display: inline-block;
    font-family: $font-family-sec;
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    vertical-align: middle;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }

  .post-classic-title + * {
    margin-top: 10px;
  }

  .post-classic-title {
    a:hover {
      color: $black;
    }
  }
}

// Post quote
.post-quote {

  .quote-default {
    max-width: none;
  }
}

a.post-quote {
  width: 100%;
  padding: 30px;
  text-align: center;
  background-color: $gray-lighter;

  @include media-breakpoint-up(md) {
    padding: 35px 30px;
  }

  .quote-default__text {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
  .quote-default__cite {
    color: $gray;
  }

  &:hover {
    box-shadow: $shadow-area-sm;
  }
}

// Post audio
.post-audio {
  .rd-audio-wrap {
    padding: 40px;
    background-color: $gray-lighter;
  }
}

// Post video

.post-video-wrap {
  @media (min-width: 1600px) {
    .embed-responsive-16by9 {
      &:before {
        padding-top: 35.45%; 
      }
     
    }
  }
}

.embed-responsive {
  position: relative;
  z-index: 2;
}

// Post minimal
//

.post-minimal {
  text-align: left;

  * + .post-meta {
    margin-top: 10px;
  }

  .owl-carousel {
    .owl-dots {
      margin-top: 10px;
    }
  }

  * + .post-classic-body {
    margin-top: 20px;
  }

  * + & {
    margin-top: 50px;

    @include media-breakpoint-up(md) {
      margin-top: 60px;
    }
  }
}

.post-minimal {
  @include media-breakpoint-up(md) {
    .quote-line__main {
      h4 {
        font-size: 22px;
      }

      h5 {
        font-size: 16px;
      }
    }
  }
}

.post-minimal-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  * + .button {
    margin-top: 25px;
  }
}

// blog masonry
//

.posts-lists-masonry-3-cols {
  position: relative;
  z-index: 1;
  > * {
    margin-top: 50px;
  }

  .owl-carousel .owl-stage-outer {
    z-index: -1;
  }
  .rd-audio {
    position: relative;
    left: 0;
  }

  @include media-breakpoint-up(md) {
    -moz-columns: 2;
    columns: 2;
    -moz-column-gap: 50px;
    column-gap: 50px;

    > * {
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
      margin-top: 0;
      margin-bottom: 60px;
      display: inline-block;
      width: 100%;
    }

    @include media-breakpoint-up(xl) {
      -moz-columns: 3;
      columns: 3;
      transform: translateY(0);
      margin-bottom: -50px;

      > * {
        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }
}

// blog justify 
//

.carousel-blog-justify {
  .owl-dots {
    display: none !important;
  }
  @include media-breakpoint-up(lg) {
    display: block;
  }
}

// single post
//

.post-single-body {
  > * + img,
  > * + p {
    margin-top: 16px;
  }

  * + .quote-default__cite {
    margin-top: 27px;
  }

  .owl-carousel + * {
    margin-top: 20px;
  }

  .quote-default__cite {
    color: $gray;
  }

  @include media-breakpoint-up(lg) {
    > * + img,
    > * + p {
      margin-top: 34px;
    }
  }
}

// nonstandard post
.nonstandard-post {
  position: relative;
}

.nonstandard-post-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.nonstandard-post-header {
  position: relative;
  z-index: 2;
  img + * { margin-top: 20px; }
  * + h2 { margin-top: 25px; }
  * + .post-meta { margin-top: 30px; }
  * + [class*='list-inline-'] { margin-top: 50px; }

  img {
    width: auto;
  }

  * {
    text-align: center;
  }

  [class*='linear-icon-'] {
    &:before {
      font-size: 24px;
    }
  }

  .post-meta {
    font-size: $font-size-base;
  }
}

// Post modern
.post-modern {
  position: relative;
  overflow: hidden;
  text-align: center;
  width: 100%;
  margin: -1px;
  padding-bottom: 75.3950338%;
  @include responsive-block;

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    min-height: 101%;
    min-width: 101%;
  }

  .post-modern__inner {
    color: $white;

    a {
      color: $white;

      &:hover {
        color: $black;
      }
    }
  }

  .list-inline-tag {
    font-style: italic;
    font-size: 12px;
    color: $white;
  }
}

.post-modern__inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: rgba($primary, .66);
  pointer-events: none;

  > * {
    position: relative;
    z-index: 1;
    pointer-events: auto;
  }

  > * + * {
    margin-top: 20px;
  }
}

.post-modern__title {
  max-height: 5.5em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-modern__divider {
  width: 116px;
  height: 1px;
  background: $white;
}

@include media-breakpoint-up(lg) {
  .post-modern__inner {
    > * + * {
      margin-top: 30px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .post-modern__inner {
    > * + * {
      margin-top: 45px;
    }
  }
}

.desktop {
  .post-modern {
    > img {
      will-change: transform;
      transition: all .4s ease-out;
    }

    .post-modern__inner {
      opacity: 0;
      transition: all .4s ease-out;
      background: rgba($primary, .9);

      > * {
        transform: scale(0);
        transition: all 0.3s ease-out;
      }

      @for $i from 1 through 5 {
        > *:nth-child(#{$i}) {
          transition-delay: .1s * $i;
        }
      }
    }

    &:hover {
      > img {
        transform: translate3d(-50%, -50%, 0) scale(.9);
      }

      .post-modern__inner {
        opacity: 1;

        > * {
          transform: scale(1);
        }
      }
    }
  }
}

// Post
.post {
  text-align: left;
}

.post__image {
  border: 8px solid $gray-lighter;
}

.post__tags {
  font-family: $font-family-sans-serif-2;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: .08em;
  color: $body-color;
  text-transform: uppercase;

  a {
    color: inherit;

    &:hover {
      color: $black;
    }
  }
}

.post__meta {
  margin-left: -9px;
  margin-right: -9px;
  font-size: 12px;
  line-height: 1.3;

  > li {
    position: relative;
    display: inline-block;
    padding-left: 9px;
    padding-right: 9px;
  }

  a {
    color: $body-color;

    &:hover {
      color: $primary;
    }
  }

  > li:not(:last-child) {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 3px;
      height: 0;
      border-bottom: 1px solid;
    }
  }
}

* + .post__tags {
  margin-top: 15px;
}

* + .post__meta {
  margin-top: 15px;
}

* + .post__title {
  margin-top: 6px;
}

@include media-breakpoint-up(lg) {

  * + .post__tags {
    margin-top: 28px;
  }
}

@include media-breakpoint-up(xl) {
  .post__image {
    border-width: 15px;
  }
}

// Post
.post-product {
  text-align: left;
  
  .price {
    position: relative;
    top: 0;
  }
  
  * + .list-marked {
    margin-top: 15px;
  }
}

.post-product__title {
  position: relative;
  z-index: 5;
}

.post-product__image-wrap {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 8px solid $gray-lighter;
    z-index: 1;

    @include media-breakpoint-up(lg) {
      border-width: 15px;
    }
  }
}

* + .post-product__body {
  margin-top: 20px;
}

@include media-breakpoint-up(lg) {
  .desktop {
    .post-product__image-wrap::before {
      transition: .33s ease-out;
    }
    
    .post-product {
      &:hover {
        .post-product__image-wrap::before {
          border-width: 5px;
        }
      }
    }
  }
}