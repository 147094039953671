// Mixins

// Clearfix
@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

// Text variant
@mixin text-variant($parent, $color, $hover-color) {
  #{$parent} {
    color: $color;
  }
  a#{$parent}:hover,
  a#{$parent}:focus {
    color: $hover-color;
  }
}